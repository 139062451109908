<template>
  <div>
    <div :id="'ex-direction'+modelId" class="pattern-holder ex-directionx"></div>
    <!-- <button id="resize">设置</button>
    <button id="getPattern" @click="getPatterns">获取</button> -->
  </div>
</template>
<script setup>
import { ElMessage, ElMessageBox } from "element-plus";
import { Plus } from "@element-plus/icons-vue";
import axios from "../../src/commons/AxiosMethod.js";
import $ from "jquery";
import PatternLock from "../../src/assets/patternLocks/patternlock.js";

import {
  ref,
  onBeforeMount,
  onUnmounted,
  onMounted,
  computed,
  reactive,
  nextTick,
} from "vue";

const emit = defineEmits(["update:modelValue"]);

const props = defineProps({
  modelValue: {
    type: String,
    default: '',
  },
  modelId: {
    type: String,
    default: '',
  },
});
const password = ref("");
// const password = computed({
//   get() {
//     return props.modelValue;
//   },
//   set(val) {
//     props.modelValue = val;
//     emit("update:modelValue", val);
//   },
// });
 
onMounted(() => {
  console.log(111)
  password.value = props.modelValue;

  var lock = new PatternLock("#ex-direction"+props.modelId, {
    onDraw(pattern) {
      password.value = pattern;
      emit("update:modelValue", pattern);
    },
  });
  if (
    password.value == null ||
    password.value == "" ||
    password.value == undefined ||
    password.value == "undefined"
  ) { 
  }else{
    lock.setPattern(password.value);
  }

});
 
</script> 
  