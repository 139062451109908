<template>
  <div>
    <div>
      <meta charset="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta name="description" content="Declaración - repairnote.es" />
      <meta name="keywords" content="Declaración - repairnote.es" />
      <meta name="author" content="Lapus" />
      <title>Declaración - 声明- repairnote.es</title>
      <link
        rel="preload"
        href="fonts/Unicons.woff2"
        as="font"
        type="font/woff2"
        crossorigin=""
      />
      <link rel="shortcut icon" href="img/favicon.png" />
      <link rel="stylesheet" href="css/plugins.css" />
      <link rel="stylesheet" href="css/style.css" />
      <nav
        id="sticky-navbar"
        class="navbar navbar-expand-lg classic transparent navbar-light navbar-clone fixed"
      >
        <!-- /.container -->
      </nav>
      <div class="content-wrapper">
        <header class="wrapper bg-soft-primary">
          <nav
            class="navbar navbar-expand-lg center-nav transparent position-absolute navbar-dark"
          >
            <div class="container flex-lg-row flex-nowrap align-items-center">
              <div class="navbar-brand w-100">
                <a href="https://htmlpage.cn"
                  ><img src="img/logo-small-svg.svg" alt="" class="logo-dark"
                /></a>
              </div>
              <div
                class="navbar-collapse offcanvas offcanvas-nav offcanvas-start"
              >
                <div class="offcanvas-header d-lg-none">
                  <h3 class="text-white fs-30 mb-0">HTMLPAGE.CN</h3>
                  <button
                    type="button"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                    class="btn-close btn-close-white"
                  >
                    Send
                  </button>
                </div>
                <div class="offcanvas-body ms-lg-auto d-flex flex-column h-100">
                  <ul class="navbar-nav">
                    <li class="nav-item"></li>
                    <li class="nav-item dropdown">
                      <ul class="dropdown-menu">
                        <li class="dropdown dropdown-submenu dropend">
                          <a
                            href="#"
                            data-bs-toggle="dropdown"
                            class="dropdown-item dropdown-toggle"
                            >用户体验</a
                          >
                          <ul class="dropdown-menu">
                            <li class="nav-item">
                              <a href="./services.html" class="dropdown-item"
                                >交互体验设计</a
                              >
                            </li>
                            <li class="nav-item">
                              <a href="./services2.html" class="dropdown-item"
                                >体验与用户行为</a
                              >
                            </li>
                          </ul>
                        </li>
                        <li class="dropdown dropdown-submenu dropend">
                          <a
                            href="#"
                            data-bs-toggle="dropdown"
                            class="dropdown-item dropdown-toggle"
                            >视觉设计</a
                          >
                          <ul class="dropdown-menu">
                            <li class="nav-item">
                              <a href="./about.html" class="dropdown-item"
                                >界面设计</a
                              >
                            </li>
                            <li class="nav-item">
                              <a href="./about2.html" class="dropdown-item"
                                >平面设计</a
                              >
                            </li>
                          </ul>
                        </li>
                        <li class="nav-item">
                          <a href="./pricing.html" class="dropdown-item"
                            >架构设计</a
                          >
                        </li>
                      </ul>
                    </li>
                    <li class="nav-item dropdown">
                      <div class="dropdown-menu dropdown-lg">
                        <div class="dropdown-lg-content">
                          <div>
                            <h6 class="dropdown-header">体验设计</h6>
                            <ul class="list-unstyled">
                              <li>
                                <a href="./projects.html" class="dropdown-item"
                                  >某电商平台</a
                                >
                              </li>
                              <li>
                                <a href="./projects2.html" class="dropdown-item"
                                  >某电商平台</a
                                >
                              </li>
                            </ul>
                          </div>
                          <!-- /.column -->
                          <div>
                            <h6 class="dropdown-header">交互设计</h6>
                            <ul class="list-unstyled">
                              <li>
                                <a
                                  href="./single-project.html"
                                  class="dropdown-item"
                                  >某游戏平台</a
                                >
                              </li>
                              <li>
                                <a
                                  href="./single-project2.html"
                                  class="dropdown-item"
                                  >某游戏平台</a
                                >
                              </li>
                            </ul>
                          </div>
                          <!-- /.column -->
                        </div>
                        <!-- /auto-column -->
                      </div>
                    </li>
                    <li class="nav-item dropdown">
                      <ul class="dropdown-menu">
                        <li class="nav-item">
                          <a href="./blog.html" class="dropdown-item"
                            >企业介绍</a
                          >
                        </li>
                        <li class="nav-item">
                          <a href="./blog2.html" class="dropdown-item"
                            >企业文化</a
                          >
                        </li>
                        <li class="dropdown dropdown-submenu dropend">
                          <a
                            href="#"
                            data-bs-toggle="dropdown"
                            class="dropdown-item dropdown-toggle"
                            >联系方式</a
                          >
                          <ul class="dropdown-menu">
                            <li class="nav-item">
                              <a href="./blog-post.html" class="dropdown-item"
                                >联系电话</a
                              >
                            </li>
                            <li class="nav-item">
                              <a href="./blog-post2.html" class="dropdown-item"
                                >电子邮件</a
                              >
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <!-- /.navbar-nav -->
                  <div class="offcanvas-footer d-lg-none">
                    <div>
                      <a href="mailto:first.last@email.com" class="link-inverse"
                        >help@lapus.cn</a
                      ><br />
                      00 (123) 456 78 90 <br />
                      <nav class="nav social social-white mt-4">
                        <a href="#"><i class="uil uil-twitter"></i></a
                        ><a href="#"><i class="uil uil-facebook-f"></i></a
                        ><a href="#"><i class="uil uil-dribbble"></i></a
                        ><a href="#"><i class="uil uil-instagram"></i></a
                        ><a href="#"><i class="uil uil-youtube"></i></a>
                      </nav>
                      <!-- /.social -->
                    </div>
                  </div>
                  <!-- /.offcanvas-footer -->
                </div>
                <!-- /.offcanvas-body -->
              </div>
              <!-- /.navbar-collapse -->
              <div class="navbar-other w-100 d-flex ms-auto">
                <ul class="navbar-nav flex-row align-items-center ms-auto">
                  <li
                    class="nav-item dropdown language-select text-uppercase"
                  ></li>
                  <li class="nav-item d-none d-md-block"></li>
                  <li class="nav-item d-lg-none">
                    <button type="button" class="hamburger offcanvas-nav-btn">
                      Send
                    </button>
                  </li>
                </ul>
                <!-- /.navbar-nav -->
              </div>
              <!-- /.navbar-other -->
            </div>
            <!-- /.container -->
          </nav>
          <!-- /.navbar -->
        </header>
        <!-- /header -->
        <section
          data-image-src="img/bg6.jpg"
          class="wrapper image-wrapper bg-image bg-overlay text-white"
        >
          <!-- /.container -->
        </section>
        <!-- /section -->
        <section class="wrapper bg-light wrapper-border">
          <div class="container pt-14 pt-md-16 pb-13 pb-md-15">
            <div class="row">
              <div class="col-lg-10 offset-lg-1">
                <article>
                  <h2 class="display-6 mb-4">
                    <h3>Declaración</h3>
                  </h2>

                  <p>
                    Bienvenido al software repairnote.es. Por favor, lea
                    atentamente la siguiente declaración:
                  </p>
                  <ol>
                    <li>
                      <p><strong>Descripción del propósito</strong>:</p>
                      <ul>
                        <li>
                          Este software está diseñado específicamente para
                          tiendas de reparación, con el objetivo de proporcionar
                          servicios de registro de reparaciones y consulta de
                          garantías a los clientes. Este software se encuentra
                          en una fase experimental, con el objetivo de probar y
                          mejorar sus funciones.
                        </li>
                        <li>
                          Las tiendas de reparación pueden usar este software
                          para registrar la información del cliente, el
                          historial de reparaciones, el estado de la garantía,
                          etc.
                        </li>
                        <li>
                          Los clientes finales pueden consultar el estado de la
                          reparación según los términos indicados en el recibo,
                          incluyendo pero no limitado a inicio de reparación,
                          reparación completada, etc. El software también puede
                          organizar y clasificar el número y tipo de
                          reparaciones de los clientes finales.
                        </li>
                      </ul>
                    </li>
                    <li>
                      <p><strong>Prohibición de uso comercial</strong>:</p>
                      <ul>
                        <li>
                          Este software está prohibido para cualquier uso
                          comercial. Cualquier forma de uso comercial,
                          incluyendo pero no limitado a servicios pagados,
                          publicidad comercial, cooperación comercial, etc.,
                          viola esta declaración.
                        </li>
                        <li>
                          Si necesita una solución para uso comercial,
                          contáctenos para obtener más información.
                        </li>
                      </ul>
                    </li>
                    <li>
                      <p><strong>Limitación de responsabilidad</strong>:</p>
                      <ul>
                        <li>
                          De acuerdo con la Directiva de Protección del
                          Consumidor de la UE (Directiva 2011/83/UE), este
                          software, como producto experimental, no es
                          responsable de ninguna pérdida directa o indirecta
                          causada por el uso de este software. Esto incluye pero
                          no se limita a pérdida de datos, interrupción del
                          negocio, pérdidas financieras, etc.
                        </li>
                        <li>
                          Los usuarios asumen todos los riesgos posibles al usar
                          este software. Los desarrolladores de este software no
                          son responsables de ningún daño causado por defectos
                          del software o uso inadecuado.
                        </li>
                      </ul>
                    </li>
                    <li>
                      <p><strong>Privacidad de los datos</strong>:</p>
                      <ul>
                        <li>
                          Este software respeta la privacidad del usuario, no
                          recopila, almacena ni comparte ningún dato del
                          usuario. Todos los datos del cliente son gestionados y
                          protegidos por el propio usuario.
                        </li>
                        <li>
                          De acuerdo con el Reglamento General de Protección de
                          Datos de la UE (GDPR - Reglamento (UE) 2016/679), nos
                          comprometemos a garantizar la seguridad y privacidad
                          de los datos de los usuarios. Asegúrese de proteger
                          adecuadamente sus datos y hacer copias de seguridad
                          periódicamente para evitar la pérdida de datos.
                        </li>
                      </ul>
                    </li>
                    <li>
                      <p><strong>Actualización del software</strong>:</p>
                      <ul>
                        <li>
                          Este software está en constante mejora. Podemos
                          publicar actualizaciones periódicamente para corregir
                          errores, optimizar el rendimiento o agregar nuevas
                          funciones. Revise y actualice a la última versión
                          regularmente.
                        </li>
                        <li>
                          De acuerdo con los Estándares de Desarrollo de
                          Software de la UE (EN 301 549), nos esforzamos por
                          garantizar la accesibilidad y compatibilidad del
                          software.
                        </li>
                      </ul>
                    </li>
                    <li>
                      <p><strong>Aceptación de los términos</strong>:</p>
                      <ul>
                        <li>
                          Al comenzar a utilizar este software, usted acepta los
                          términos y condiciones mencionados anteriormente.
                        </li>
                      </ul>
                    </li>
                  </ol>
                  <p>
                    Gracias por su apoyo y comprensión hacia repairnote.es. Si
                    tiene alguna pregunta o sugerencia, por favor contáctenos.
                  </p>

                  <div class="row gx-0">
                    <h3>Dichiarazione</h3>
                    <!--/column --><!--/column -->
                  </div>

                  <p>
                    Benvenuto nel software repairnote.es. Si prega di leggere
                    attentamente la seguente dichiarazione:
                  </p>
                  <ol>
                    <li>
                      <p><strong>Descrizione dello scopo</strong>:</p>
                      <ul>
                        <li>
                          Questo software è specificamente progettato per i
                          negozi di riparazione, con l'obiettivo di fornire
                          servizi di registrazione delle riparazioni e
                          consultazione delle garanzie ai clienti. Questo
                          software è attualmente in una fase sperimentale, con
                          l'obiettivo di testare e migliorare le sue
                          funzionalità.
                        </li>
                        <li>
                          I negozi di riparazione possono utilizzare questo
                          software per registrare le informazioni dei clienti,
                          la cronologia delle riparazioni, lo stato delle
                          garanzie, ecc.
                        </li>
                        <li>
                          I clienti finali possono consultare lo stato della
                          riparazione secondo i termini indicati sullo
                          scontrino, inclusi ma non limitati a inizio
                          riparazione, riparazione completata, ecc. Il software
                          può anche organizzare e classificare il numero e il
                          tipo di riparazioni dei clienti finali.
                        </li>
                      </ul>
                    </li>
                    <li>
                      <p><strong>Divieto di utilizzo commerciale</strong>:</p>
                      <ul>
                        <li>
                          Questo software è vietato per qualsiasi utilizzo
                          commerciale. Qualsiasi forma di utilizzo commerciale,
                          inclusi ma non limitati a servizi a pagamento,
                          pubblicità commerciale, cooperazione commerciale,
                          ecc., viola questa dichiarazione.
                        </li>
                        <li>
                          Se hai bisogno di una soluzione per uso commerciale,
                          contattaci per ulteriori informazioni.
                        </li>
                      </ul>
                    </li>
                    <li>
                      <p><strong>Limitazione di responsabilità</strong>:</p>
                      <ul>
                        <li>
                          In conformità con la Direttiva UE sulla protezione dei
                          consumatori (Direttiva 2011/83/UE), questo software,
                          come prodotto sperimentale, non è responsabile per
                          eventuali perdite dirette o indirette causate dall'uso
                          di questo software. Questo include ma non si limita a
                          perdita di dati, interruzione dell'attività, perdite
                          finanziarie, ecc.
                        </li>
                        <li>
                          Gli utenti assumono tutti i possibili rischi
                          nell'utilizzo di questo software. Gli sviluppatori di
                          questo software non sono responsabili per eventuali
                          danni causati da difetti del software o uso improprio.
                        </li>
                      </ul>
                    </li>
                    <li>
                      <p><strong>Privacy dei dati</strong>:</p>
                      <ul>
                        <li>
                          Questo software rispetta la privacy degli utenti, non
                          raccoglie, archivia o condivide alcun dato degli
                          utenti. Tutti i dati dei clienti sono gestiti e
                          protetti dall'utente stesso.
                        </li>
                        <li>
                          In conformità con il Regolamento generale sulla
                          protezione dei dati dell'UE (GDPR - Regolamento (UE)
                          2016/679), ci impegniamo a garantire la sicurezza e la
                          privacy dei dati degli utenti. Assicurati di
                          proteggere adeguatamente i tuoi dati e di eseguire
                          backup periodici per evitare la perdita di dati.
                        </li>
                      </ul>
                    </li>
                    <li>
                      <p><strong>Aggiornamento del software</strong>:</p>
                      <ul>
                        <li>
                          Questo software è in continua evoluzione. Potremmo
                          pubblicare aggiornamenti periodicamente per correggere
                          bug, ottimizzare le prestazioni o aggiungere nuove
                          funzionalità. Controlla e aggiorna regolarmente
                          all'ultima versione.
                        </li>
                        <li>
                          In conformità con gli standard di sviluppo software
                          dell'UE (EN 301 549), ci sforziamo di garantire
                          l'accessibilità e la compatibilità del software.
                        </li>
                      </ul>
                    </li>
                    <li>
                      <p><strong>Accettazione dei termini</strong>:</p>
                      <ul>
                        <li>
                          Iniziare a utilizzare questo software implica
                          l'accettazione dei termini e delle condizioni sopra
                          menzionati.
                        </li>
                      </ul>
                    </li>
                  </ol>
                  <p>
                    Grazie per il tuo supporto e comprensione verso
                    repairnote.es. Se hai domande o suggerimenti, contattaci.
                  </p>
                </article>
                <h3>声明</h3>

                <p>欢迎使用 repairnote.es 软件。请仔细阅读以下声明内容：</p>
                <ol>
                  <li>
                    <p><strong>用途说明</strong>：</p>
                    <ul>
                      <li>
                        本软件专为维修店设计，旨在为客户提供维修登记和保修查询服务。该软件目前处于实验性阶段，旨在测试和改进功能。
                      </li>
                      <li>
                        维修店可以使用本软件记录客户信息、维修历史、保修状态等相关数据。
                      </li>
                      <li>
                        终端客户可以根据小票中的条款查询维修状态，包括但不限于开始维修、完成维修等。软件还可对终端客户的维修次数和类型进行整理和统计。
                      </li>
                    </ul>
                  </li>
                  <li>
                    <p><strong>禁止商业使用</strong>：</p>
                    <ul>
                      <li>
                        本软件禁止用于任何商业模式。任何形式的商业使用，包括但不限于收费服务、商业广告、商业合作等，均违反本声明。
                      </li>
                      <li>
                        如果您需要商业用途的解决方案，请联系我们以获取更多信息。
                      </li>
                    </ul>
                  </li>
                  <li>
                    <p><strong>责任限制</strong>：</p>
                    <ul>
                      <li>
                        根据《欧盟消费者保护指令》（Directive
                        2011/83/EU），本软件作为实验性产品，不对任何由于使用本软件而产生的直接或间接损失承担责任。这包括但不限于数据丢失、业务中断、财务损失等。
                      </li>
                      <li>
                        用户在使用本软件时，应自行承担可能的风险。本软件开发者对因软件缺陷或使用不当造成的任何损害不承担责任。
                      </li>
                    </ul>
                  </li>
                  <li>
                    <p><strong>数据隐私</strong>：</p>
                    <ul>
                      <li>
                        本软件尊重用户的隐私权，不会收集、存储或分享任何用户数据。所有客户数据均由用户自行管理和保护。
                      </li>
                      <li>
                        根据《欧盟通用数据保护条例》（GDPR - Regulation (EU)
                        2016/679），我们承诺确保用户数据的安全和隐私。请确保妥善保管您的数据，并定期备份，以防数据丢失。
                      </li>
                    </ul>
                  </li>
                  <li>
                    <p><strong>软件更新</strong>：</p>
                    <ul>
                      <li>
                        本软件处于不断改进的过程中。我们可能会不定期发布更新，以修复漏洞、优化性能或添加新功能。请定期检查并更新到最新版本。
                      </li>
                      <li>
                        根据《欧盟软件开发标准》（EN 301
                        549），我们将努力确保软件的无障碍性和兼容性。
                      </li>
                    </ul>
                  </li>
                </ol>
                <p>
                  感谢您对 repairnote.es
                  的支持和理解。如有任何疑问或建议，请联系我们的客服团队。
                </p>
              </div>
              <!-- /column -->
            </div>
            <!-- /.row -->
          </div>
          <!-- /.container -->
          <div class="container-fluid px-md-6"><!-- /.swiper-container --></div>
          <!-- /.container-fluid -->
        </section>
        <!-- /section -->
        <section class="wrapper bg-light"><!-- /.container --></section>
        <!-- /section -->
      </div>
      <!-- /.content-wrapper -->
      <footer class="bg-dark text-inverse">
        <div class="container py-13 py-md-15">
          <p class="mb-4">
            © 2024 Reparinote.es<br class="d-none d-lg-block" />All rights
            reserved.
          </p>
          <div class="row gy-6 gy-lg-0">
            <div class="col-md-4 col-lg-3">
              <div class="widget"><!-- /.social --></div>
              <!-- /.widget -->
            </div>
            <!-- /column --><!-- /column --><!-- /column --><!-- /column -->
          </div>
          <!--/.row -->
        </div>
        <!-- /.container -->
      </footer>
    </div>
  </div>
</template>

  <script>
import axios from "../../commons/AxiosMethod.js";
import tools from "../../commons/tools.js";
import navigation from "../../components/Modules/Navigation.vue";
import BoxGridPassword from "../../components/BoxGridPassword.vue";
import {
  ElMessageBox,
  ElMessage,
  ElNotification,
  UploadProps,
} from "element-plus";
import { CloseBold, Plus, Delete, Bell } from "@element-plus/icons-vue";
import * as echarts from "echarts";

export default {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  data() {
    return {
      Password: "1,2,3,4,5",
    };
  },
  name: "App",
  mounted() {
    this.DonotPromptWindow = sessionStorage.getItem("DonotPrompt") != "YES";
  },

  components: {},
  methods: {},
};
</script>

    
  <style scoped>
@import "../../assets/aboutus/css/plugins.css";
@import "../../assets/aboutus/css/style.css";
* {
  box-sizing: border-box;
}

body {
  margin: 0;
}

* {
  box-sizing: border-box;
}

body {
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica,
    Roboto, Arial, "PingFang SC", "Hiragino Sans GB", "Microsoft Yahei",
    "Microsoft Jhenghei", sans-serif;
}

.logo path {
  pointer-events: none;
  fill: none;
  stroke-linecap: round;
  stroke-width: 7;
  stroke: rgb(255, 255, 255);
}

.wechat-group img {
  max-width: 220px;
  height: auto;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  margin-top: 0px;
  margin-right: auto;
  margin-bottom: 0px;
  margin-left: auto;
  width: 100%;
}

.welcome-img img {
  width: 100%;
}

#header:not(.sticky-header) #logo a {
  opacity: 0;
  pointer-events: none;
}
</style>